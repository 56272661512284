<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="等级名称:">
                    <el-input class="s-input" v-model="queryParams.title" placeholder="请输入等级名称" clearable></el-input>
                </el-form-item>

                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
                <el-button type="info" @click="showAdvice()">须知</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column label="关联会员等级" align="center" width="120" >
                    <template slot-scope="scope">
                        <el-tag type="primary" >{{ scope.row.user_grade.name }}:{{ scope.row.user_grade.id }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="等级显示名称" prop="title" width="200" align="center"></el-table-column>
                <el-table-column label="描述" prop="desc" width="300" align="center"></el-table-column>
                <el-table-column label="icon图片" prop="icon" align="center" width="80">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;border-radius:25px;margin-top: 10px" :class="`column${scope.$index}`" class="table-td-thumb" :src="scope.row.icon" :preview-src-list="[scope.row.icon]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column label="有效期" prop="time" width="150" align="center"></el-table-column>
                <el-table-column label="普通会员" prop="giving_invitecode_num" width="150">
                    <template slot-scope="scope">
                        <el-tag type="primary" >开通即赠积分数量:{{ scope.row.give_integral }}</el-tag>
                        <el-tag type="primary" >发放月卡数量:{{ scope.row.give_card_num }}</el-tag>
                        <el-tag type="primary" >预充金额:{{ scope.row.prestore_amount }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="分销商/合伙人" prop="giving_invitecode_num" width="200">
                    <template slot-scope="scope">
                        <el-tag type="primary" >发放会员邀请码数量:{{ scope.row.giving_invitecode_num }}</el-tag>
                        <el-tag type="primary" >第一次采购单价:{{ scope.row.first_buy_price }}￥</el-tag>
                        <el-tag type="primary" >重复采购单价:{{ scope.row.repeat_buy_price }}￥</el-tag>
                        <el-tag type="primary" >重复采购起始数量:{{ scope.row.repeat_buy_num }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="售价(￥)" prop="price" width="180" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="100">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑客户等级 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="900px" v-if="addVisible" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="180px" size="large" element-loading-text="上传中">
                <el-form-item label="等级" prop="grade_id">
                    <el-select v-model="form.grade_id" placeholder="请选择等级" class="handle-select mr10" @change="changeShop" :disabled="disabled">
                        <el-option v-for="items in gradeList" :key="items.id" :label="items.name" :value="items.level">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="desc" >
                    <el-input v-model="form.desc" placeholder="请输入描述" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="售价" prop="price">
                    <el-input v-model="form.price" placeholder="请输入价格"></el-input>
                </el-form-item>
                <el-form-item label="有效期(天)" prop="time">
                    <el-input v-model="form.time" placeholder="请输入有效期"></el-input>
                </el-form-item>
                <!-- 图片上传 -->
                <el-form-item label="图片" prop="icon">
                    <el-input v-model="form.icon" placeholder="请输入图片链接"></el-input>
                    <el-button @click.prevent="choiceImg">选择图片</el-button>
                    <el-upload class="avatar-uploader up" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.icon" :src="form.icon" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸400*400像素</span>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-divider content-position="center">普通会员</el-divider>
                <el-form-item label="开通即赠积分数量" prop="give_integral">
                  <el-input v-model="form.give_integral" placeholder="请输入发放积分数量"></el-input>
                </el-form-item>
                <el-form-item label="每月赠积分数量" prop="give_integral_monthly">
                    <el-input v-model="form.give_integral_monthly" placeholder="请输入发放积分数量"></el-input>
                </el-form-item>
                <el-divider content-position="center">分销商</el-divider>
                <el-form-item label="会员码数量" prop="giving_invitecode_num">
                    <el-input v-model="form.giving_invitecode_num" placeholder="请输入赠送会员码数量"></el-input>
                </el-form-item>
                <el-form-item label="发放月卡数量" prop="give_card_num">
                    <el-input v-model="form.give_card_num" placeholder="请输入发放月卡数量"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="adviceVisible" width="800px" center>
            <el-divider >普通会员</el-divider>
            <div>
                <h4>1.设置相应有效期（天），设置相应的赠送月卡发放数量</h4>
                <h4>2.把发放会员邀请码数量设置:0</h4>
                <h4>3.把第一次采购单价设置:0.00</h4>
                <h4>4.把重复采购起始单价:0.00</h4>
                <h4>5.把重复采购起始数量:0</h4>
            </div>
            <el-divider >合伙人/经销商</el-divider>
            <div>
                <h4>1.把月卡发放数量为0 ，设置相应的有效期</h4>
                <h4>2.设置发放会员邀请码数量</h4>
                <h4>3.设置第一次采购单价</h4>
                <h4>4.设置重复采购起始优惠价</h4>
                <h4>5.设置重复采购起始数量</h4>
            </div>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import {request} from '@/common/request'
import {deal} from '@/common/main'
import loadEvents from '@/utils/loading'
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            sysConfigs:{},
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            disabled: false,
            adviceVisible:false,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['客户', '升级配置'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            imageUrl: '',
            header: {},
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            brandList: [],
            addVisible: false,
            addVisibles: false,
            title: '添加升级配置',
            rules: {

                grade_id: [{
                    required: true,
                    message: '等级id不能为空',
                    trigger: 'blur'
                }],

                title: [{
                    required: true,
                    message: '标题不能为空',
                    trigger: 'blur'
                }],

                desc: [{
                    required: true,
                    message: '描述不能为空',
                    trigger: 'blur'
                }],

                price: [{
                    required: true,
                    message: '价格不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                time: [{
                    required: true,
                    message: '有效期不能为空',
                    trigger: 'blur'
                },
                {

                    message: "只能输入非零的正整数"
                }],
                icon: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                giving_invitecode_num: [{
                    required: true,
                    message: '会员码发放数量不能为空',
                    trigger: 'blur'
                }],
                give_integral: [{
                  required: true,
                  message: '积分赠送数量不能为空',
                  trigger: 'blur'
                }],
                first_buy_price: [{
                    required: true,
                    message: '首次采购单价不能为空',
                    trigger: 'blur'
                }],
                repeat_buy_num: [{
                        required: true,
                        message: '复采起始数量不能为空',
                        trigger: 'blur'
                    }],
                repeat_buy_price: [{
                    required: true,
                    message: '复采价格不能为空',
                    trigger: 'blur'
                }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            mode: 'add',
            form: {
                time:0,
            },
            gradeList: [],
            shop: 0,
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
        this.getGradeList()
        this.getGradeConfig()
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'icon', url);
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //获取等级列表
        getGradeList() {
            request.get("/account/grade/list", { page: 1, pagesize: -1, sttae: 2 }).then((ret) => {
                if (ret.code == 1) {
                    this.gradeList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },
        //获取等级列表配置
        getGradeConfig() {
            request.get("/account/grade/goods/config", {  }).then((ret) => {
                if (ret.code === 1) {
                    this.sysConfigs = ret.data;
                    console.log(this.sysConfigs)
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },
        //状态
        state(row, state) {
            if (state == 1) {
                this.confirmContent = '确定是否下线?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            } else {
                this.confirmContent = '确定是否上线?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            }
        },

        loadData() {
            this.loading = true;
            //升级配置列表
            request.get('/account/grade/goods/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.time = val.time + '天'
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'icon', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        add(id, index) {
            this.mode = 'add';
            this.disabled = false
            this.form = { num: 1, countType: 1, ty: 1 }
            this.addVisible = true
            this.form = {
                state: 2,
                ty: 1
            };
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.disabled = true
            this.disabled = false
            this.title = '编辑';
            //处理时间
            this.form = Object.assign({}, row);
            this.form.price = this.form.price + ''
            this.form.time = this.form.time + ''
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        delete(id, index) {

        },
        changeCount(num) {
            this.form.num = num
        },
        changeDatePicker: function () {
            let vue = this
            if (vue.pickerDatad != null) {
                vue.form.stime = vue.pickerDatad[0]
                vue.form.etime = vue.pickerDatad[1]
            } else {
                vue.form.stime = null
                vue.form.etime = null
            }
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let time = parseInt(this.form.time)
                        let params = this.form = Object.assign({}, this.form);
                        params.time = time
                        //   this.form.num = this.form.countType == 1 ? 1 : this.form.num;
                        // this.form.brand_ids = this.form.brand_id;
                        var url = this.mode === 'add' ? '/account/grade/goods/add' : '/account/grade/goods/edit';
                        request.post(url, params).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/grade/goods/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        changeShop(val) {

            this.gradeList.map(item => {
                if (item.id === val) {
                    return this.shop = item.shop
                }
            })
        },
        showAdvice(){
            this.adviceVisible=!this.addVisible
        }
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
